import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import './ressource.css'

import format from '../../assets/images/ressource/format.jpg'
import surveil from '../../assets/images/ressource/surveil2.jpg'
import emb from '../../assets/images/ressource/emb2.png'
import equip from '../../assets/images/ressource/equip.jpg'

import { Container, Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';


const Service = () => {


  const [visible, setVisible] = useState(false); // State pour gérer la visibilité de la boîte

  const boxRefs = useRef(null);


  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      const windowHeight = window.innerHeight;

      // Vérifier si l'élément est dans la vue
      if (boxRefs.current && boxRefs.current.offsetTop < scrollTop + windowHeight) {
        // Afficher la boîte après un délai
        setTimeout(() => {
          setVisible(true);
        }, 1000); // Délai de 1 seconde pour l'animation
      }
    };

    // Écouter les événements de scroll
    window.addEventListener('scroll', handleScroll);

    // Nettoyer l'écouteur d'événement lors du démontage du composant
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []); // Le tableau vide [] assure que cet effet s'exécute uniquement une fois après le montage initial

  const boxVariants = {
    hidden: { opacity: 0, y: -500 },
    visible: { opacity: 1, y: 0 },
  };

  return < >
    <motion.div

      initial="hidden"
      animate={visible ? 'visible' : 'hidden'}
      variants={boxVariants}
      transition={{ duration: 0.5 }}
      ref={(el) => (boxRefs.current = el)}
    >
      <Row>
        <Col lg='3'>
          <div className="card btn">
          <a href='./bpo'>
            <div className="card-bodye">
              <div className='m-auto text-center'>
                <span className="card-title">BPO </span>
              </div>
              <p className="">
                BPO (Business Process Outsourcing) consists of the delegation of certain internal processes by specialized service providers or suppliers.
                .</p>

            </div>
          </a>
          </div>
        </Col>

        <Col lg='3'>
          <div className="card btn">
          <a href='./customer_service'>
            <div className="card-bodye">
              <div className='m-auto text-center'>
                <span className="card-title">Customer Service </span>
              </div>
              <p className='pt-2'>
                We support companies to answer questions,
                to the feelings or problems encountered by their customers.


              </p>
            </div>
            </a>
          </div>
        </Col>

        <Col lg='3' >
          <div className="card btn">
          <a href='./translation'>
            <div className="card-bodye">

              <div className='m-auto text-center'>
                <span className="card-title">Translation</span>
              </div>
              <p className='pt-2'>
                We have a very qualified translator team

              </p>
            </div>
            </a>
          </div>
        </Col>

        <Col lg='3' >
          <div className="card mb-2 btn">
          <a href='./data_collection'>
            <div className="card-bodye">
              <div className='m-auto text-center'>
                <span className="card-title">Data Collection</span>
              </div>
              <p className='pt-2'>
                This is the process we use to label raw data
                in order to improve the learning of our clients' AI models.
              </p>
            </div>
          </a>
          </div>
        </Col>
      </Row>
    </motion.div>
  </>;
};

export default Service;
