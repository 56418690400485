import { Route, Routes, Navigate } from 'react-router-dom'


import Home from '../pages/Home'

import Contact from '../pages/Contact'

import BPO from '../components/service/bpo'
import Customer from '../components/service/customer'
import Translation from '../components/service/translation'
import Collection from '../components/service/collection'

import ProtectedRoute from './ProtectedRoute'
import Signup from '../Dashboard/Signup'
import Login from '../Dashboard/Login'
import Dashboard from '../Dashboard/dashboard'


import Dashboard_admin from '../Dashboard_ADdmin/dashboard'
import Users from '../Dashboard_ADdmin/users'

import About from '../pages/About'
import NotFound from '../pages/Notfound';




const Routers = () => {
  return (
    <Routes>
      <Route path="*" element={<NotFound />} />
      <Route path='/' element={<Navigate to='home' />} />
      <Route path='home' element={<Home />} />
      <Route path='about' element={<About />} />
      <Route path='contact' element={<Contact />} />

      <Route path='bpo' element={<BPO />} />
      <Route path='customer_service' element={<Customer />} />
      <Route path='translation' element={<Translation />} />
      <Route path='data_collection' element={<Collection />} />

      <Route path='/*' element={<ProtectedRoute />} >

        <Route path='dashboard' element={<Dashboard />} />
        <Route path='dashboard/signup' element={<Signup />} />

        <Route path='dashboard__admin' element={<Dashboard_admin />} />
        <Route path='dashboard__admin/users' element={<Users />} />

      </Route>


      <Route path='dashboard/login' element={<Login />} />


    </Routes>

  )
}

export default Routers
