import { Route, Routes, useLocation } from 'react-router-dom';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import Routers from '../../routers/Routers';
import AdminNavs from '../../Dashboard/adminNav';


const Layout = () => {
  const location = useLocation();

  return (
    <>
      {location.pathname.startsWith('/dashboard') || location.pathname.startsWith('/dashboard_admin') ? (
        <AdminNavs />
      ) : (
        <Header />
      )}

      <div>
        <Routes>
          <Route path="/*" element={<Routers />} />
        </Routes>
      </div>
      <Footer />
    </>
  );
};

export default Layout;
