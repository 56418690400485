
import React from 'react'
import './footer.css'

import { Container, Row, Col, ListGroup, ListGroupItem } from 'reactstrap';
import { Link } from 'react-router-dom';
import './footer.css'



const Footer = () => {
  
  const handleLinkdata = (e) => {
    window.location.href = '/data_collection';
  };

  const handleLinkbpo = (e) => {
    window.location.href = '/bpo';
  };


  const handleLinktranslation = (e) => {
    window.location.href = '/translation';
  };


  const handleLinkcustomer = (e) => {
    window.location.href = '/customer_service';
  };

  const handleLinkcontact = (e) => {
    window.location.href = '/contact';
  };

  return (
    <footer className='footer p-3 mt-5'>
      <Container>

        <Row >

          <Col lg="6" className='mb-4' md='8'>
            <div className="footer__quick-links explora">

              <h4 className='text-white '>Explora Customer Services </h4>

            </div>
            <p className="footer__text mt-4">
              Explora customer services is a company based in Togo,
              more precisely in Lome, which
              connects through its network to offer solutions in the
              management and development of your customer relations.
            </p>

          </Col>


          <Col lg="3" className='mb-4' md='4'>
            <div className="footer__quick-links">
              <h4 className="quick__links-title">Others Links </h4>
              <ListGroup className='mb-3'>
                <ListGroupItem className='ps-0 border-0'>
                  <Link onClick={handleLinkcontact} >Contact </Link>
                </ListGroupItem>

                <ListGroupItem className='ps-0 border-0'>
                  <Link onClick={handleLinkbpo} > Outsourcing Service</Link>
                </ListGroupItem>

                <ListGroupItem className='ps-0 border-0'>
                  <Link onClick={handleLinkcustomer} > Customer Service</Link>
                </ListGroupItem>

                <ListGroupItem className='ps-0 border-0'>
                  <Link onClick={handleLinkdata} > Translation Service</Link>
                </ListGroupItem>

                <ListGroupItem className='ps-0 border-0'>
                  <Link onClick={handleLinkdata} > Data Collection Service</Link>
                </ListGroupItem>

              </ListGroup>

            </div>
          </Col>

          <Col lg="3" md='4'>
            <div className="footer__quick-links">
              <h4 className="quick__links-title">Contact</h4>
              <ListGroup className='footrt__contact'>
                <ListGroupItem className='ps-0 border-0 d-flex align-item-center gap-2'>
                  <span ><i class="ri-map-pin-line"></i></span>
                  <span>Lomé, Togo</span>
                </ListGroupItem>

                <ListGroupItem className='ps-0 border-0 d-flex align-item-center gap-2 '>
                  <div className='d-flex align-item-center'>
                    <span className='mt-3'><i class="ri-phone-line"></i></span>

                    <ul className="">
                      <li>
                        <span>+228 22255598</span>
                      </li>
                      <li>
                        <span>+228 90085725  </span>
                      </li>
                      <li>
                        <span> +228 90008780</span>
                      </li>
                    </ul>
                  </div>

                </ListGroupItem>

                <ListGroupItem className='ps-0 border-0 d-flex align-item-center gap-2'>
                  <span ><i class="ri-mail-line"></i></span>
                  <span >explorainvestgroup@gmail.com</span>
                </ListGroupItem>
              </ListGroup>
            </div>
          </Col>

        </Row>
        <hr />

        <div>
          <p className="footer__copyright"> © 2024 explora </p>
        </div>

      </Container>

    </footer>
  )
}

export default Footer
