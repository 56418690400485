import React, { useState, useEffect, useRef } from 'react';

import { Helmet } from 'react-helmet';

import '../styles/home.css';

import { useNavigate } from 'react-router-dom';


import { Container, Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';

import Ressource from '../components/Ressource/Ressource';
import Service from '../components/service/service';


import b3 from '../assets/images/call4.jpg'




const Home = () => {

  const navigate = useNavigate()

  const year = new Date().getFullYear();

  const [visibleBox, setVisibleBox] = useState(Array(5).fill(false));

  const [visible, setVisible] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setVisible(true);
    }, 1000); // Délai avant l'apparition de la boîte
  }, []);



  const boxVariants1 = {
    hidden: { opacity: 0, y: -500 },
    visible: { opacity: 1, y: 0 },
  };


  const boxRefs = useRef([]);
  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      const windowHeight = window.innerHeight;

      boxRefs.current.forEach((ref, index) => {
        // Vérifier si l'élément est dans la vue
        if (ref && ref.offsetTop < scrollTop + windowHeight) {
          // Utiliser setTimeout pour ajouter un délai progressif
          const timeoutId = setTimeout(() => {
            setVisibleBox((prevVisibleBox) => {
              const newVisibleBox = [...prevVisibleBox];
              newVisibleBox[index] = true;
              return newVisibleBox;
            });
          }, index * 1000); // Chaque élément apparaît avec un délai de 200ms d'intervalle
          // Conserver l'ID du timeout pour le nettoyage ultérieur
          timeoutIds.push(timeoutId);
        }
      });
    };

    // Utiliser un tableau de références pour les timeouts
    const timeoutIds = [];

    // Écouter les événements de scroll
    window.addEventListener('scroll', handleScroll);

    // Nettoyer les timeouts lors du démontage du composant
    return () => {
      window.removeEventListener('scroll', handleScroll);
      // Nettoyer tous les timeouts enregistrés
      timeoutIds.forEach((timeoutId) => clearTimeout(timeoutId));
    };
  }, []); // Le tableau vide [] assure que cet effet s'exécute uniquement une fois après le montage initial




  const boxVariants2 = {
    hidden: { opacity: 0, x: 500 },
    visible: { opacity: 1, x: 0 },
  };

  return <>
 <Helmet>
        <title>Exploracustomerservices - Let’s talk about contact center outsourcing</title>
        <meta name="description" content=" Explora customer service homepage. Introducing services such as call center outsourcing, translation and other services" />
      </Helmet>

    <div className='  next' >
      <a href='./contact' className='butt-link '>Contact us</a>
    </div>

    <section className="hero__section ">
      <motion.div
        initial="hidden"
        animate={visible ? 'visible' : 'hidden'}
        variants={boxVariants2}
        transition={{ duration: 0.5 }}
      >
        <div className="cont">
          <div class="  mb-2 p-4">
            <h6>Contact Center Outsourcing </h6>
            <span className='text-white'>
              Delivering exceptional customer  all integrated support channels,<br />
              we make lasting connections that will inspire your customers<br />
              to love and share your brand.
            </span>
          </div>
        </div>
      </motion.div>
    </section>


    <section className="padding ">
      <div>

        <Ressource />

      </div>
    </section>

    <section className="p-3 ">
      <div className="container">
        <div className="row">
          <Col lg='5' className='p-2'>
              <div>
                <div className='m-auto text-center homeimg'>
                  <img src={b3} className="img-fluid rounded-start p-2 " alt="..." />
                </div>
              </div>
          </Col>
          <Col lg='7' className='discute'>
            <div className="text-center  offre ">
              <h2>Let’s discuss your contact center outsourcing project. </h2>
            </div>
            <p>Explora customer services has modern IT equipment and a network of experienced telephone advisors. We offer solutions to effectively meet the needs of managing incoming or outgoing international calls.</p>         
          </Col>

        </div>
      </div>
    </section>


    <section className="p-5 ">
      <div className="container-xxl">

        <Service />

      </div>
    </section>

  </>;
};

export default Home;
