import React, { useState } from 'react';
import { Container, Row, Col, Form, FormGroup, Input } from 'reactstrap';
import Helmet from '../components/Helmet/Helmet';
import '../styles/login.css';
import '../styles/dashboard.css';
import { Link, useNavigate } from 'react-router-dom';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../firebase.config';
import { toast } from 'react-toastify';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const login = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      console.log(user);
      setLoading(false);
      toast.success('Authentication successful');
      navigate('/dashboard');
    } catch (error) {
      setLoading(false);
      toast.error('Authentication failed');
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Helmet title="Login">
      <section>
        <Container>
          <Row>
            {loading ? (
              <Col lg="12" className="text-center">
                <h5 className="fw-bold">Loading.....</h5>
              </Col>
            ) : (
              <Col lg="5" className="m-auto text-center">
                <h3 className="fw-bold mb-4 mt-5">Login</h3>
                <Form className="auth__form" onSubmit={login}>
                  <FormGroup>
                    <Input
                      type="email"
                      placeholder="Enter your email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required
                    />
                  </FormGroup>

                  <FormGroup className="position-relative">
                    <Input
                      type={showPassword ? 'text' : 'password'}
                      placeholder="Enter your password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      required
                    />
                    <i
                      className={`mdi ${showPassword ? 'ri-eye-off-line' : 'ri-eye-line'} position-absolute`}
                      style={{ right: 10, top: 10, cursor: 'pointer' }}
                      onClick={togglePasswordVisibility}
                    />
                  </FormGroup>

                  <button type="submit" className="buy__b auth__btn">
                  login
                  </button>
                </Form>
              </Col>
            )}
          </Row>
        </Container>
      </section>
    </Helmet>
  );
};

export default Login;
