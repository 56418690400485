import React, { useRef } from 'react'
import { Container, Row } from 'reactstrap';

import useAuth from '../custom-hooks/useAuth';
import { signOut } from "firebase/auth";
import { auth } from '../firebase.config';
import { toast } from 'react-toastify';



import '../styles/admin-nav.css';
import { Link, NavLink, useNavigate, useLocation } from 'react-router-dom';

import { VscAccount } from 'react-icons/vsc'





const AdminNavs = () => {

  const headerRef = useRef(null);

  const menuRef = useRef(null);

  const location = useLocation();
  const navigate = useNavigate()

  const { currentUser } = useAuth()

  const logout = () => {

    signOut(auth).then(() => {
      toast.success('Logged out')
      navigate('/home')
    }).catch(err => {
      toast.error(err.message)
    })
  }

  return (
    <>
      <header ref={headerRef} className='admin__header'>
       
          <Container>
            <div className="admin__nav-warpper-top">
              <div className="logo">
                <h2>Dashboard</h2>
              </div>

              <div className="util pt-2">
              
              <NavLink  >
                  <div className="d-flex">
                    <div className=" Navlink">
                    
                      <ul >
                        <li><a  >
                         
                            <div className="d-flex">
                              {currentUser ? (
                                <p onClick={logout} className='text-white'>Logout</p>
                              ) : (
                                <p>Login</p>
                              )}

                            </div>
                          
                        </a></li>
                        
                      </ul>
                    </div>

                  </div>
                </NavLink>
              </div>
              
            </div>
          </Container>
        
      </header>

    </>
  )

}

export default AdminNavs
