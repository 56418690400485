import React, { useState } from 'react'
import { Container, Row, Col, Form, FormGroup,Input,FormFeedback } from 'reactstrap';

import Helmet from '../components/Helmet/Helmet';
import '../styles/login.css';
import '../styles/dashboard.css'

import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

import { createUserWithEmailAndPassword, updateProfile } from "firebase/auth";


import { getDocs, query, collection, where, setDoc, doc, serverTimestamp } from 'firebase/firestore';

import { auth } from '../firebase.config';

import { db } from '../firebase.config';

import { toast } from 'react-toastify';



const Signup = () => {

  const [enterfirstname, setEnterFirstName] = useState('');
  const [enterlastname, setEnterLastName] = useState('');
  const [email, setEmail] = useState('');
  const [enterphone, setEnterPhone] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [passwordError, setPasswordError] = useState(false); 


  const handlePasswordChange = (e) => {
    const value = e.target.value;
    setPassword(value);
    setPasswordError(value.length < 6); // Vérifie si la longueur du mot de passe est inférieure à 6
  };
  
  const navigate = useNavigate()

  const signup = async (e) => {
    e.preventDefault();
    setLoading(true);
  
    try {
      // Vérifier si l'email existe déjà dans Firestore
      const emailQuery = query(collection(db, "utilisateur"), where("email", "==", email));
      const querySnapshot = await getDocs(emailQuery);
  
      if (!querySnapshot.empty) {
        toast.error("Email already exists. Please use a different email.");
        setLoading(false);
        return;
      }
  
      // Créer un nouvel utilisateur avec Firebase Authentication
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
  
      // Stocker les données utilisateur dans la base de données Firestore
      await setDoc(doc(db, "utilisateur", user.uid), {
        uid: user.uid,
        FirstName: enterfirstname,
        LastName: enterlastname,
        Phone: enterphone,
        Date: serverTimestamp(),
        email,
      });
  
      toast.success("Account creation successful");
      navigate('/dashboard/login');
    } catch (error) {
      toast.error('Account creation failed');
    } finally {
      setLoading(false);
    }
  };
  

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return <Helmet title="Signup">
    <section>
      <Container>
        <Row>
          
                               : 
              <Col lg='5' className='m-auto text-center m-4'>
              <h3 className='fw-bold mb-4'>Signup</h3>
  
              <Form className="auth__form" onSubmit={signup}>

              <FormGroup   >
                    <Input
                      type="text"
                      name="firstname"
                      placeholder="Enter your first name"
                      value={enterfirstname}
                      onChange={(e) => setEnterFirstName(e.target.value)}
                      required
                     
                    />
                  </FormGroup>

                  <FormGroup >
                    <Input
                      type="text"
                      name="lastname"
                      placeholder="Enter your last name"
                      value={enterlastname}
                      onChange={(e) => setEnterLastName(e.target.value)}
                      required
            
                    />
                  </FormGroup>
  
                <FormGroup  >
                  <Input 
                  type="email" 
                  placeholder='Enter your email'
                    value={email} 
                    onChange={e => setEmail(e.target.value)} 
                     required />
                </FormGroup>

                <FormGroup >
                    <Input
                      type="text"
                      name="phone"
                      placeholder="Enter your phone number"
                      value={enterphone}
                      onChange={(e) => setEnterPhone(e.target.value)}
                      required
                      
                    />
                  </FormGroup>
  
                  <FormGroup className="position-relative">
                    <Input
                      type={showPassword ? 'text' : 'password'}
                      placeholder="Enter your password"
                      value={password}
                      onChange={handlePasswordChange}
                      invalid={passwordError} // Ajoute une indication d'invalidité si l'erreur est vraie
                      required
                    />
                    <FormFeedback>Password must be at least 6 characters long.</FormFeedback>
                    <i
                      className={`mdi ${showPassword ? 'ri-eye-off-line' : 'ri-eye-line'} position-absolute`}
                      style={{ right: 10, top: 10, cursor: 'pointer' }}
                      onClick={togglePasswordVisibility}
                    />
                  </FormGroup>
  
  
                <button type='submit' className="buy__b auth__btn">register</button>
                <p className='text-white' >Already have an account? {" "}
                  <Link to='/dashboard/login'>Login</Link></p>
              </Form>
  
            </Col>
          
        </Row>
      </Container>
    </section>

  </Helmet>
}

export default Signup
