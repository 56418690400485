import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import './ressource.css'

import format from '../../assets/images/ressource/format.jpg'
import surveil from '../../assets/images/ressource/surveil2.jpg'
import emb from '../../assets/images/ressource/bpo.jpeg'
import equip from '../../assets/images/ressource/equip.jpg'

import { Container, Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';


const Ressource = () => {


  const [visible, setVisible] = useState(false); // State pour gérer la visibilité de la boîte

  const boxRefs = useRef(null);


  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      const windowHeight = window.innerHeight;

      // Vérifier si l'élément est dans la vue
      if (boxRefs.current && boxRefs.current.offsetTop < scrollTop + windowHeight) {
        // Afficher la boîte après un délai
        setTimeout(() => {
          setVisible(true);
        }, 1000); // Délai de 1 seconde pour l'animation
      }
    };

    // Écouter les événements de scroll
    window.addEventListener('scroll', handleScroll);

    // Nettoyer l'écouteur d'événement lors du démontage du composant
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []); // Le tableau vide [] assure que cet effet s'exécute uniquement une fois après le montage initial

  const boxVariants = {
    hidden: { opacity: 0, y: -500 },
    visible: { opacity: 1, y: 0 },
  };

  return < >
    <motion.div
     className='resour'
      initial="hidden"
      animate={visible ? 'visible' : 'hidden'}
      variants={boxVariants}
      transition={{ duration: 0.5 }}
      ref={(el) => (boxRefs.current = el)}
    >
      <Row>
        <Col lg='3' >
          <div className="card">
            <img src={equip} className="card-img-top img-fluid mb-3 ressource " alt="..." />
            <div className="card-bod">
              <div className='m-auto text-center'>
                <span className="card-title">Equipment </span>
              </div>
              <p className="pt-2">
                Software for managing incoming and outgoing calls, automatic routing, interactive voice response systems.
                .</p>

            </div>
          </div>
        </Col>

        <Col lg='3'>
          <div className="card">
            <img src={format} className="card-img-top img-fluid mb-3 ressource" alt="..." />
            <div className="card-bod">
              <div className='m-auto text-center'>
                <span className="card-title">Training and Development </span>
              </div>
              <p className='pt-2'>
                Comprehensive training for new agents on products/services.

              </p>
            </div>
          </div>
        </Col>

        <Col lg='3' >
          <div className="card">
            <img src={emb} className="card-img-top img-fluid mb-3 ressource" alt="..." />
            <div className="card-bod">
              <div className='m-auto text-center'>
                <span className="card-title">Human Resources </span>
              </div>
              <p className='pt-2'>
                Rigorous recruitment process to hire qualified and motivated agents.

              </p>
            </div>
          </div>
        </Col>

        <Col lg='3' >
          <div className="card mb-2">
            <img src={surveil} className="card-img-top img-fluid mb-3 ressource" alt="..." />
            <div className="card-bod">
              <div className='m-auto text-center'>
                <span className="card-title">Quality Management</span>
              </div>
              <p className='pt-2'>
                Live call monitoring or recordings to evaluate quality and provide feedback.

              </p>
            </div>

          </div>
        </Col>
      </Row>
    </motion.div>
  </>;
};

export default Ressource;
