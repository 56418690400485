import React, { useState, useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet';

import { Container, Row, Col } from 'reactstrap';
import '../styles/login.css'
import { motion } from 'framer-motion';


import b from '../assets/images/about1.jpg'

import b1 from '../assets/images/serv1.webp'
import b2 from '../assets/images/about2.png'
import b3 from '../assets/images/serv3.jpg'

const About = () => {



    const [visible, setVisible] = useState(false); // State pour gérer la visibilité de la boîte

    const boxRefs = useRef(null);


    useEffect(() => {
        const handleScroll = () => {
            const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
            const windowHeight = window.innerHeight;

            // Vérifier si l'élément est dans la vue
            if (boxRefs.current && boxRefs.current.offsetTop < scrollTop + windowHeight) {
                // Afficher la boîte après un délai
                setTimeout(() => {
                    setVisible(true);
                }, 100); // Délai de 1 seconde pour l'animation
            }
        };

        // Écouter les événements de scroll
        window.addEventListener('scroll', handleScroll);

        // Nettoyer l'écouteur d'événement lors du démontage du composant
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []); // Le tableau vide [] assure que cet effet s'exécute uniquement une fois après le montage initial

    const boxVariants = {
        hidden: { opacity: 0, x: -500 },
        visible: { opacity: 1, x: 0 },
    };

    const boxVariants1 = {
        hidden: { opacity: 0, x: 500 },
        visible: { opacity: 1, x: 0 },
    };

    return <>
     <Helmet>
        <title>Exploracustomerservices - About Us</title>
        <meta name="description" content=" Based in Togo, we offer solutions to businesses. You would like to know more about us and why you should choose Explora customer services for your different services." />
      </Helmet>

        <section className="about-wrapper p-5 d-flex justify-content-center align-items-center">
            <div className="container-xxl">
                <div className="row">
                    <div className="col-12">
                        <div className="shop-details text-center align-items-center">
                            <h1 className="text-white">Know Us</h1>
                            <p className='text-white fs-3'>Get to know us more</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className="about-us p-5">
            <div className="row">
                <div className="col-md-6">
                    <img src={b} alt="" className='img-fluid rounded-start p-2 h-100' />
                    
                </div>
                <div className="col-md-6  d-flex flex-column align-items-center justify-content-center">
                    <h1><b>Who are we?</b></h1>
                    <p className="card-text mb-3 mt-3">At Explora, we are committed to providing the highest quality services. Founded on the principles of excellence, reliability and customer satisfaction, our mission is to facilitate communication between companies and their customers through effective and personalized solutions.</p>
                    <p className="card-text">We firmly believe that every service is an opportunity to create a positive experience for your customers. Our mission is to become the preferred partner of businesses by offering services that meet their specific needs, while guaranteeing exceptional quality of service.</p>
                </div>
            </div>
        </section>

        <section className='services' >
            <Container>
                <Row className="service_prod p-2 ">

                    <Col lg='4'>
                        <motion.div
                            initial="hidden"
                            animate={visible ? 'visible' : 'hidden'}
                            variants={boxVariants}
                            transition={{ duration: 0.5 }}
                            ref={(el) => (boxRefs.current = el)}
                        >
                            <div className='m-auto text-center'>
                                <img src={b1} className="img-fluid rounded-start p-2 h-100" alt="..." />
                            </div>
                        </motion.div>

                    </Col >


                    <Col lg='8'  >
                        <motion.div

                            initial="hidden"
                            animate={visible ? 'visible' : 'hidden'}
                            variants={boxVariants1}
                            transition={{ duration: 0.5 }}
                            ref={(el) => (boxRefs.current = el)}
                        >
                            <div className="card-body">
                                <div className='m-auto text-center'>
                                    <h2 className="card-title">Ours Services</h2>
                                </div>
                                <p className='pt-3 pb-2'>

                                    We offer a comprehensive range of call services, including :
                                </p>
                                <span className='p-4'>   •      Customer service  </span><br />
                                <span className='p-4'>   •      Technical support  </span><br />
                                <span className='p-4'>   •      Appointment scheduling  </span><br />
                                <span className='p-4'>   •      Satisfaction surveys  </span><br />
                                <span className='p-4'>   •      Complaint management  </span><br />

                                <p className='pt-2'>     Each service is designed to enhance customer interaction and optimize our clients' operations.</p>


                            </div>
                        </motion.div>
                    </Col>


                </Row>
            </Container>

            <Container>
                <Row className=" service_prod p-2">

                    <Col lg='8'  >
                        <motion.div

                            initial="hidden"
                            animate={visible ? 'visible' : 'hidden'}
                            variants={boxVariants}
                            transition={{ duration: 0.5 }}
                            ref={(el) => (boxRefs.current = el)}
                        >
                            <div className="card-body">
                                <div className='m-auto text-center'>
                                    <h2 className="card-title">Our Team  </h2>
                                </div>
                                <p className='pt-3'>
                                    Our team is made up of qualified and dedicated professionals, trained to effectively manage each service. We continually invest in the training and development of our staff to ensure a world-class service.

                                </p>

                                <div className='m-auto text-center mt-3 mb-2'>
                                    <h2 className="card-title"> Ours Values </h2>
                                </div>
                                <span>   •      Quality: We strive for excellence in every interaction.  </span><br />
                                <span >   •      Reliability: You can count on us to be available and responsive.       </span><br />
                                <span >   •      Personalization: We tailor our services to meet the unique needs of each client.  </span><br />
                                <span >   •     Innovation: We utilize the latest technologies to continually improve our services.  </span><br />

                            </div>
                        </motion.div>
                    </Col>

                    <Col lg='4'>
                        <motion.div

                            initial="hidden"
                            animate={visible ? 'visible' : 'hidden'}
                            variants={boxVariants1}
                            transition={{ duration: 0.5 }}
                            ref={(el) => (boxRefs.current = el)}
                        >
                            <div className='m-auto text-center'>
                                <img src={b2} className="img-fluid rounded-start p-2 h-120" alt="..." />
                            </div>
                        </motion.div>
                    </Col >

                </Row>
            </Container>

            <Container >
                <Row className="service_prod p-2">

                    <Col lg='4'>
                        <motion.div

                            initial="hidden"
                            animate={visible ? 'visible' : 'hidden'}
                            variants={boxVariants}
                            transition={{ duration: 0.5 }}
                            ref={(el) => (boxRefs.current = el)}
                        >
                            <div className='m-auto text-center'>
                                <img src={b3} className="img-fluid rounded-start p-2 h-100" alt="..." />
                            </div>
                        </motion.div>
                    </Col >
                    <Col lg='8' className='pl-2' >
                        <motion.div

                            initial="hidden"
                            animate={visible ? 'visible' : 'hidden'}
                            variants={boxVariants1}
                            transition={{ duration: 0.5 }}
                            ref={(el) => (boxRefs.current = el)}
                        >
                            <div className="card-body">
                                <div className='m-auto text-center'>
                                    <h2 className="card-title">Why Choose Explora Customer Services </h2>
                                </div>
                                <p className='p-3'>
                                    Choosing Explora customer services means choosing a partner dedicated to your success. We are committed to providing a service that not only meets, but exceeds your expectations. Our customer-centric approach, combined with our expertise and commitment to innovation, makes us the ideal choice for all your service needs.
                                </p>
                            </div>
                        </motion.div>
                    </Col>

                </Row>

            </Container>

            <div className=" p-2 d-flex justify-content-center align-items-center">
                <div className="container-xxl">
                    <div className="row">
                        <div className="col-12">
                            <div className="shop-details text-center align-items-center">
                                <h2>Contact Us</h2>
                                <div className=''>
                                    <p >To learn more about our services and how we can help you ,<br /> wenhance your customer interactions, please feel free to contact us.<br /> We look forward to working with you and contributing to your success.</p>
                                </div>
                                <div className='  butt' >
                                    <a href='./contact' className='button-link '>Contact us</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>




        </section>





    </>

}

export default About
