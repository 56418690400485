import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'reactstrap';
import useGetsData from '../custom-hooks/useGetsData';
import { db } from '../firebase.config';
import { doc, deleteDoc } from 'firebase/firestore';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

import '../styles/dashboard.css';




const Dashboard = () => {
  const { data: messages, loading } = useGetsData('Message');
  const [messagesData, setMessagesData] = useState(messages);
  const navigate = useNavigate();

  useEffect(() => {
    setMessagesData(messages);
  }, [messages]);

  useEffect(() => {
    const sortedMessages = [...messages].sort((a, b) => b.Date - a.Date);
    setMessagesData(sortedMessages);
  }, [messages]);

  const deleteMessage = async (id) => {
    await deleteDoc(doc(db, 'Message', id));
    toast.success('Message deleted successfully!');
  };

  return (
    <Container>
      <Row>
        <Col lg='12'>
          <div className="table-responsive mb-5 mt-5">
            <table className='table bordered'>
              <thead>
                <tr>
                  <th style={{ width: 120 }}>Country</th>
                  <th style={{ width: 150 }}>First Name</th>
                  <th style={{ width: 150 }}>Last Name</th>
                  <th style={{ width: 100 }}>Email</th>
                  <th style={{ width: 100 }}>Company</th>
                  <th style={{ width: 150 }}>Phone</th>
                  <th style={{ width: 400 }}>Message</th>
                  <th style={{ width: 130 }}>Date</th>
                 
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <h3 className='py-5 fw-bold'>Loading...</h3>
                ) : (
                  messagesData.map(item => (
                    <tr key={item.id}>
                      <td>{item.Country}</td>
                      <td>{item.FirstName}</td>
                      <td>{item.LastName}</td>
                      <td>{item.Email}</td>
                      <td>{item.Company}</td>
                      <td>{item.Phone}</td>
                      <td>{item.Message}</td>
                      <td>
                        {item.Date ? `${item.Date.toDate().toLocaleString('en-GB', { timeZone: 'UTC' })} UTC ` : ""}
                      </td>
                      
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Dashboard;
