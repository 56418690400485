import React, { useState, useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet';
import CommoSection from '../UI/CommoSection';
import { Container, Row, Col } from 'reactstrap';
import { motion } from 'framer-motion';
import './ressource.css'
import {  useLocation, Link } from 'react-router-dom';



import b1 from '../../assets/images/bpo.jpeg'


const BPO = () => {

  const location = useLocation();

  const boxRefs = useRef([]);

  const [visibleBox, setVisibleBox] = useState(Array(5).fill(false));
  
 
  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      const windowHeight = window.innerHeight;

      boxRefs.current.forEach((ref, index) => {
        // Vérifier si l'élément est dans la vue
        if (ref && ref.offsetTop < scrollTop + windowHeight) {
          // Utiliser setTimeout pour ajouter un délai progressif
          const timeoutId = setTimeout(() => {
            setVisibleBox((prevVisibleBox) => {
              const newVisibleBox = [...prevVisibleBox];
              newVisibleBox[index] = true;
              return newVisibleBox;
            });
          }, index * 1000); // Chaque élément apparaît avec un délai de 200ms d'intervalle
          // Conserver l'ID du timeout pour le nettoyage ultérieur
          timeoutIds.push(timeoutId);
        }
      });
    };

    // Utiliser un tableau de références pour les timeouts
    const timeoutIds = [];

    // Écouter les événements de scroll
    window.addEventListener('scroll', handleScroll);

    // Nettoyer les timeouts lors du démontage du composant
    return () => {
      window.removeEventListener('scroll', handleScroll);
      // Nettoyer tous les timeouts enregistrés
      timeoutIds.forEach((timeoutId) => clearTimeout(timeoutId));
    };
  }, []); // Le tableau vide [] assure que cet effet s'exécute uniquement une fois après le montage initial


  
  const boxesData = [
    {
      titre : 'Are you looking to optimize your resources and focus your team on the essentials? At Explora, we specialize in marketing outsourcing, customer relationship management, data management, flow management. We partner with businesses like yours to deliver customized solutions that reduce costs, increase efficiency and free up your internal resources for strategic initiatives.'
    },

    {
     
      entete: 'Here are the reasons to choose Explora',
    },
    {
      text: '• Our outsourcing solutions are designed to deliver significant cost savings without compromising quality or security.',
    },
    {
      text: '• Designed to grow with your business, our services are flexible and scalable to meet your evolving needs.',
    },
    {
      text: '• We pride ourselves on delivering innovative solutions tailored to your specific industry challenges, ensuring you stay ahead in a competitive market.'
    },
    {
      text: '• Our team is committed to providing personalized, round-the-clock support, ensuring seamless integration and continuous improvement.'
    }

  ];

  const boxVariant = {
    hidden: { opacity: 0, x: -500 },
    visible: { opacity: 1, x: 0 },
  };


  const handleLinkdata = (e) => { 
    window.location.href = '/data_annotation';
};

const handleLinkbpo = (e) => {
    window.location.href = '/bpo';
};


const handleLinktranslation = (e) => { 
    window.location.href = '/translation';
};


const handleLinkcustomer = (e) => { 
  window.location.href = '/customer_service';
};

  return <>

    <Helmet>
        <title> Services -  Contact Center Outsourcing </title>
        <meta name="description" content=" Are you looking to optimize your resources and focus your team on the essentials? At Explora customer services, we specialize in call center outsourcing, customer relationship management and data management." />
      </Helmet>
   
    <CommoSection title=" Business Process Outsourcing " />
    <section className=" servi  m-3 ">
      <Container>
        <Row className="service_p">

          <Col lg='8'>
            <div>
              <div className='m-auto text-center'>
                <img src={b1} className="img-fluid rounded-start p-2 " alt="..." />
              </div>
            </div>
          </Col >


          <Col lg='4' className='ser' >
            <div >
              <div className="card-body">
                <div className=' text-center'>
                  <h1 className="card-title">OUR SOLUTIONS </h1>
                </div>
                <ul className="navlink">
                  <li >
                    <Link onClick={handleLinkbpo} className={location.pathname === '/bpo' ? 'active' : 'inactive'}>
                      Outsourcing Service
                    </Link>
                  </li>
                  <li >
                    <Link onClick={handleLinkcustomer} className={location.pathname === '/customer_service' ? 'active' : 'inactive'}>
                      Customer Service
                    </Link>
                  </li>
                  <li >
                    <Link onClick={handleLinktranslation} className={location.pathname === '/translation' ? 'active' : 'inactive'}>
                      Translation
                    </Link>
                  </li>
                  <li >
                    <Link onClick={handleLinkdata} className={location.pathname === '/data_collection' ? 'active' : 'inactive'}>
                      Data Collection
                    </Link>
                  </li>
                </ul>

              </div>
            </div>
          </Col>


        </Row>
      </Container>

      
      <Container>
        <Row className="service_p ">

          <Col lg='8'>
            <div>
            <div className="text-center align-items-center offre pt-3">
              <h2> Business Process Outsourcing (BPO) </h2>
            </div>
              <div className='p-2'>
              {boxesData.map((box, index) => (
              <motion.div
                key={index} 
                initial="hidden"
                 className='p-2'
                animate={visibleBox[index] ? "visible" : "hidden"}
                variants={boxVariant}
                transition={{ duration: 0.5 }}
                ref={(el) => (boxRefs.current[index] = el)}
              >
                <span className='' >{box.titre}</span>
                <div className='entete'>
                <span  >{box.entete} </span>
                </div>
                <span className=' p-3'>{box.text}</span>
              </motion.div>
            ))}
              


              </div>
            </div>
          </Col >


          <Col lg='4' className='ser' >
            <div >
              <div className=" call">
                <div className=' p-2 text-center'>
                  <span className='callnow'>call us now</span>
                </div>
                <ul className="">
                  <li>
                  <span>+228 22255598</span>
                  </li>
                  <li>
                   <span>+228 90085725  </span>
                  </li>
                  <li>
                   <span> +228 90008780</span>
                  </li>
                </ul>

              </div>
            </div>
          </Col>


        </Row>
      </Container>





    </section>
  </>


}

export default BPO
