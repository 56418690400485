import { initializeApp } from "firebase/app";
import {getAuth} from 'firebase/auth'
import {getFirestore} from 'firebase/firestore'


const firebaseConfig = {
  apiKey: "AIzaSyDq5mxRUlxNk8VLTtmyNEptFgYnYPUh_2s",
  authDomain: "exploraservices-63079.firebaseapp.com",
  projectId: "exploraservices-63079",
  storageBucket: "exploraservices-63079.appspot.com",
  messagingSenderId: "1077826729174",
  appId: "1:1077826729174:web:660dba9677782ab9740577"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);


export const auth = getAuth(app)
export const db = getFirestore(app)