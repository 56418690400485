import React, { useState, useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet';
import CommoSection from '../UI/CommoSection';
import { Container, Row, Col, Form, FormGroup, Label, Input, Button, ListGroup, ListGroupItem } from 'reactstrap';
import { motion } from 'framer-motion';
import './ressource.css'
import { NavLink, useNavigate, useLocation, Link } from 'react-router-dom';



import b1 from '../../assets/images/data_coll.jpg'




const Collection = () => {

  const location = useLocation();

  const boxRefs = useRef([]);

  const [visibleBox, setVisibleBox] = useState(Array(5).fill(false));
  
 
  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      const windowHeight = window.innerHeight;

      boxRefs.current.forEach((ref, index) => {
        // Vérifier si l'élément est dans la vue
        if (ref && ref.offsetTop < scrollTop + windowHeight) {
          // Utiliser setTimeout pour ajouter un délai progressif
          const timeoutId = setTimeout(() => {
            setVisibleBox((prevVisibleBox) => {
              const newVisibleBox = [...prevVisibleBox];
              newVisibleBox[index] = true;
              return newVisibleBox;
            });
          }, index * 1000); // Chaque élément apparaît avec un délai de 200ms d'intervalle
          // Conserver l'ID du timeout pour le nettoyage ultérieur
          timeoutIds.push(timeoutId);
        }
      });
    };

    // Utiliser un tableau de références pour les timeouts
    const timeoutIds = [];

    // Écouter les événements de scroll
    window.addEventListener('scroll', handleScroll);

    // Nettoyer les timeouts lors du démontage du composant
    return () => {
      window.removeEventListener('scroll', handleScroll);
      // Nettoyer tous les timeouts enregistrés
      timeoutIds.forEach((timeoutId) => clearTimeout(timeoutId));
    };
  }, []); // Le tableau vide [] assure que cet effet s'exécute uniquement une fois après le montage initial


  const boxesData = [
    {
      text: ' We offer custom data collection services across all modalities - text, image, audio, video - in any languagen, enabling us to create tailored datasets for training AI models.Our global workforce and advanced technology allow us to rapidly source and curate high-quality, diverse data at scale, including hard-to-find or niche data requirements.',
    },
    
    
  ];
  
  const boxVariant = {
    hidden: { opacity: 0, x: -500 },
    visible: { opacity: 1, x: 0 },
  };


  const handleLinkdata = (e) => { 
    window.location.href = '/data_collection';
};

const handleLinkbpo = (e) => {
    window.location.href = '/bpo';
};


const handleLinktranslation = (e) => { 
    window.location.href = '/translation';
};


const handleLinkcustomer = (e) => { 
  window.location.href = '/customer_service';
};

  return < >
     <Helmet>
        <title>Services - We collect data for image, audio and video to finally train the AI ​​model </title>
        <meta name="description" content=" We offer custom data collection services in all modalities text, image, audio, video in any language, which allows us to create tailor-made datasets for training AI models." />
      </Helmet>

    <CommoSection title=" Data Collection " />
    <section className=" servi  m-3">
      <Container>
        <Row className="service_p  ">

          <Col lg='8'>
            <div>
              <div className='m-auto text-center'>
                <img src={b1} className="img-fluid rounded-start p-2 " alt="..." />
              </div>
            </div>
          </Col >


          <Col lg='4' className='ser' >
            <div >
              <div className="card-body">
                <div className=' text-center'>
                  <h1 className="card-title">OUR SOLUTIONS </h1>
                </div>
                <ul className="navlink">
                  <li >
                    <Link onClick={handleLinkbpo} className={location.pathname === '/bpo' ? 'active' : 'inactive'}>
                      Outsourcing Service
                    </Link>
                  </li>
                  <li >
                    <Link onClick={handleLinkcustomer} className={location.pathname === '/customer_service' ? 'active' : 'inactive'}>
                      Customer Service
                    </Link>
                  </li>
                  <li >
                    <Link onClick={handleLinktranslation} className={location.pathname === '/translation' ? 'active' : 'inactive'}>
                      Translation
                    </Link>
                  </li>
                  <li >
                    <Link onClick={handleLinkdata} className={location.pathname === '/data_collection' ? 'active' : 'inactive'}>
                      Data Collection
                    </Link>
                  </li>
                </ul>

              </div>
            </div>
          </Col>


        </Row>
      </Container>

      <Container>
        <Row className="service ">

          <Col lg='8'>
            <div>
            <div className="text-center align-items-center offre pt-3">
              <h2>Data Collection </h2>
            </div>
              <div className='p-2'>
              {boxesData.map((box, index) => (
              <motion.div
                key={index}
                className='pt-2'
                initial="hidden"
                animate={visibleBox[index] ? "visible" : "hidden"}
                variants={boxVariant}
                transition={{ duration: 0.5 }}
                ref={(el) => (boxRefs.current[index] = el)}
              >
                
                <p>{box.text}</p>
              </motion.div>
            ))}
              


              </div>
            </div>
          </Col >


          <Col lg='4' className='ser' >
            <div >
              <div className=" call">
                <div className=' p-2 text-center'>
                  <span className='callnow'>call us now</span>
                </div>
                <ul className="">
                  <li>
                  <span>+228 22255598</span>
                  </li>
                  <li>
                   <span>+228 90085725  </span>
                  </li>
                  <li>
                   <span> +228 90008780</span>
                  </li>
                </ul>

              </div>
            </div>
          </Col>


        </Row>
      </Container>







    </section>
  </>


}

export default Collection
