import React, { useState, useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet';
import CommoSection from '../components/UI/CommoSection';
import { Container, Row, Col, Form, FormGroup, Label, Input, Button } from 'reactstrap';
import '../styles/login.css';
import { motion } from 'framer-motion';
import { db } from '../firebase.config';
import { collection, addDoc, serverTimestamp } from 'firebase/firestore';
import { toast } from 'react-toastify';

import b1 from '../assets/images/contactus.jpg';

const Contact = () => {
  const [entercountry, setEnterCountry] = useState('');
  const [enteremail, setEnterEmail] = useState('');
  const [enterphone, setEnterPhone] = useState('');
  const [enterfirstname, setEnterFirstName] = useState('');
  const [enterlastname, setEnterLastName] = useState('');
  const [entercompany, setEnterCompany] = useState('');
  const [entermsg, setEnterMsg] = useState('');

  const [visible, setVisible] = useState(false);

  const addMessage = async (e) => {
    e.preventDefault();
   

    try {
      const docRef = await collection(db, 'Message');
      await addDoc(docRef, {
        Country: entercountry,
        Email: enteremail,
        Phone: enterphone,
        FirstName: enterfirstname,
        LastName: enterlastname,
        Company: entercompany,
        Message: entermsg,
        Date: serverTimestamp(),
      });

      window.location.reload()
      toast.success('Message enregistré');
      setEnterCountry('');
      setEnterEmail('');
      setEnterPhone('');
      setEnterFirstName('');
      setEnterLastName('');
      setEnterCompany('');
      setEnterMsg('');
      
    } catch (error) {
     
      toast.error('Erreur lors de l\'envoi du message ');
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setVisible(true);
    }, 1000);
  }, []);

  const boxVariants1 = {
    hidden: { opacity: 0, x: 500 },
    visible: { opacity: 1, x: 0 },
  };

  return (
    <>
     <Helmet>
        <title>Exploracustomerservices - Contact </title>
        <meta name="description" content=" Contact explora customer service to discuss your call center outsourcing and for your translations" />
      </Helmet>
        <section className="contact-wrapper p-5 d-flex justify-content-center align-items-center">
            <div className="container-xxl">
                <div className="row">
                    <div className="col-12">
                        <div className="shop-details text-center align-items-center">
                            <h1 className="text-white">Contact us</h1>
                           
                        </div>
                    </div>
                </div>
            </div>
        </section>
      <section className="contact__prod">
        <Container>
          <Row className='contacte'>
            <Col lg='6' className='m-auto text-center'>
              <div className='footer__contact'>
                <img src={b1} className="img-fluid rounded-start p-2" alt="Contact us" />
              </div>
            </Col>
            <Col lg='6' className='contact'>
              <motion.div
                initial="hidden"
                animate={visible ? 'visible' : 'hidden'}
                variants={boxVariants1}
                transition={{ duration: 0.5 }}
              >
                <div className='m-auto text-center contnous'>
                  <h2>Contact Us</h2>
                </div>
                <Form onSubmit={addMessage}>
                  <Label for="country">Country*</Label>
                  <FormGroup>
                    <Input
                      type="text"
                      name="country"
                      placeholder="Enter your country"
                      value={entercountry}
                      onChange={(e) => setEnterCountry(e.target.value)}
                      required
                      className='p-2'
                    />
                  </FormGroup>
                  <Label for="phone">Phone*</Label>
                  <FormGroup>
                    <Input
                      type="text"
                      name="phone"
                      placeholder="Enter your phone number"
                      value={enterphone}
                      onChange={(e) => setEnterPhone(e.target.value)}
                      required
                      className='p-2'
                    />
                  </FormGroup>
                  <Label for="firstname">First Name*</Label>
                  <FormGroup>
                    <Input
                      type="text"
                      name="firstname"
                      placeholder="Enter your first name"
                      value={enterfirstname}
                      onChange={(e) => setEnterFirstName(e.target.value)}
                      required
                      className='p-2'
                    />
                  </FormGroup>
                  <Label for="lastname">Last Name*</Label>
                  <FormGroup>
                    <Input
                      type="text"
                      name="lastname"
                      placeholder="Enter your last name"
                      value={enterlastname}
                      onChange={(e) => setEnterLastName(e.target.value)}
                      required
                      className='p-2'
                    />
                  </FormGroup>
                  <Label for="email">Business Email*</Label>
                  <FormGroup>
                    <Input
                      type="email"
                      name="email"
                      placeholder="Bussiness@gmail.com"
                      value={enteremail}
                      onChange={(e) => setEnterEmail(e.target.value)}
                      required
                      className='p-2'
                    />
                  </FormGroup>
                  <Label for="company">Company Name*</Label>
                  <FormGroup>
                    <Input
                      type="text"
                      name="company"
                      placeholder="Enter your company name"
                      value={entercompany}
                      onChange={(e) => setEnterCompany(e.target.value)}
                      required
                      className='p-2'
                    />
                  </FormGroup>
                  <Label for="msg">Message*</Label>
                  <FormGroup>
                    <Input
                      type="textarea"
                      name="msg"
                      placeholder="Your message"
                      value={entermsg}
                      onChange={(e) => setEnterMsg(e.target.value)}
                      required
                      className='p-2'
                    />
                  </FormGroup>
                  <div className='envoyer'>
                    <Button type="submit" color="primary">Send</Button>
                  </div>
                </Form>
              </motion.div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}

export default Contact;
